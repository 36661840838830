.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(87, 82, 40, 0.523);
  z-index: 1;
}

/* rgba(0, 0, 0, 0.834); */

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid #c4b083;
  border-top: 10px solid #FFDB6B;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
