// custom color pallete
$tabprimary: #6ac5cb;
$tabsecondary: #ff9797;
$tabalt: #97c667;
$darkprimary: #202020;
$normalprimary: #292929;
$midprimary: #444444;
$lightprimary: #707070;
$paleprimary: #f5f5f5;
$altprimary: #88b8f6;
$altsecondary: #dbb2f0;
$accordion-bordercolor:#909090;

//Color overrides
$primary: #ffdb6b;
$dark: #f0cf7f;
$light: lighten(#f0cf7f, 60%);

//Accordion
$accordion-bg: #313131;
$accordion-color: #fff;
$accordion-button-active-bg: #313131;
$accordion-button-active-color: #313131;
$accordion-border-radius: "1px";

.accordion-flush .accordion-button:not(.collapsed) {
    background-color: #313131;
}

.accordion {
    border-top: 1px solid $accordion-bordercolor;
    border-bottom: 1px solid $accordion-bordercolor;
    border-left: 1px solid $accordion-bordercolor;
    border-right: 1px solid $accordion-bordercolor;
    border-radius: 2px;
}

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

html,
body {
    height: 100%;
}

//Custom theme colors
$custom-theme-colors: (
    "tabprimary": $tabprimary,
    "tabsecondary": $tabsecondary,
    "tabalt": $tabalt,
    "darkprimary": $darkprimary,
    "normalprimary": $normalprimary,
    "midprimary": $midprimary,
    "lightprimary": $lightprimary,
    "paleprimary": $paleprimary,
    "altprimary": $altprimary,
    "altsecondary": $altsecondary,
);

$theme-colors: map-merge($custom-theme-colors, $theme-colors);

@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/containers";

@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/button-group";
// @import "../../node_modules/bootstrap/scss/nav";
// @import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
// @import "../../node_modules/bootstrap/scss/modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
// @import "../../node_modules/bootstrap/scss/offcanvas";
// @import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";



.main-container {
    display: grid;
    grid-template-rows: 5em 3em 1fr 5em;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
}

main,
.map-container {
    height: calc(100vh - 5em);
    z-index: 1;
}

@import "./map-controls.scss";

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff !important;
}

footer {
    z-index: 3;
}

.logo svg path {
    fill: $primary;
}

.container-wrap {
    max-height: 480px !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.d-contents {
    display: contents !important;
}

.cursor-pointer {
    cursor: pointer;
}

.form-check-input:checked[type=checkbox] {
    background-image: url('../../src/assets/checkbox_active.svg');
  }

  .accordion-button:after {
    background-image: url('../../src/assets/arrow.svg');
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url('../../src/assets/arrow.svg');
    transform: rotate(90deg);
  }

